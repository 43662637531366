.recipe-list-page {
    margin: 0 auto;
    background-color: #e7f4ef;
    padding: 60px 10px 10px 10px;
    max-width: 960px;
    min-width: 400px;
    min-height: 500px;
}

.recipe-list-item {
    display: flex;
    align-content: stretch;
    border: solid 1px #7c9c60;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
}


.recipe-list-item:not(:first-child) {
    margin-top: 15px;
}

.recipe-list-item-photo {
    width: 100px;
    min-width: 100px;
}

a.recipe-list-item-title {
    color: #7c9c60;
    font-size: 18px;
}

.recipe-list-item-summary {
    padding-left: 10px;
}
