@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    color: #555555;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    cursor: pointer;
}