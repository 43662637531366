/*
.recipe-detail-page {
    margin: 0 auto;
    max-width: 960px;
    background-color: #e7f4ef;
    padding: 58px 10px 10px 10px;
}

.recipe-detail {;
    border: solid 1px #7c9c60;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
}

.recipe-detail-title {
    color: #7c9c60;
    font-weight: bold;
    font-size: 2em;
}
*/
.recipe-detail-label {
    color: #7c9c60;
    font-weight: bold;
}

/*
.recipe-detail-section {
    margin-top: 15px;
    font-size: 1.5em;
    line-height: 1.1em;
}
*/
.recipe-detail-section li {
    margin-top:8px;
}

.markdown h2,
.markdown h3,
.markdown h4 {
    color: #7c9c60;
}

.recipe-detail-tags {
    line-height: 40px;
}

@media screen and (max-width: 600px) {
    .media-hide-top-photo {
        margin-top: 16px;
        display: block;
    }
    .media-float-photo {
        display: none;
    }
}
@media screen and (min-width: 600px) {
    .media-hide-top-photo {
        display: none;
    }
    .media-float-photo {
        display: block;
        float: right;
    }
}